var imgtHeight,paginationElement;
paginationElement = document.getElementById("s31-pagination");

/*$(document).on("mouseenter", ".s31-article-box", function () {
	imgtHeight = $(this).find(".s31-article-sizes").height();

	$(this).find(".s31-article-sizes").show();
	$(this).find(".s31-article-text").css("top", -imgtHeight);
});

$(document).on("mouseleave", ".s31-article-box", function () {
	$(this).find(".s31-article-sizes").hide();
	$(this).find(".s31-article-text").css("top", "0");
});*/

const automaticPagination = function() {
	const indicator = document.querySelector('.s31-listing-loadMore');
	if (!indicator) {
		return {
			init: function () {}
		};
	}
	let wasPaginated = false;

	const observer = new IntersectionObserver(function (entries, observer) {
		if (entries[0].isIntersecting && !wasPaginated) {
			indicator.innerHTML = generalLoading;
			document.addEventListener('app.automatic_pagination.unobserve', unobserve);
			Multifilters.loadNextPage();
			wasPaginated = true;
		}
	}, {threshold: 1.0});

	const unobserve = function () {
		wasPaginated = true;
		observer.unobserve(indicator);
		document.removeEventListener('app.automatic_pagination.unobserve', unobserve);
	};

	indicator.addEventListener('click', function () {
		document.addEventListener('app.automatic_pagination.unobserve', unobserve);
	});

	return {
		init: function () {
			if (!indicator) {
				return;
			}

			wasPaginated = false;
			observer.observe(indicator);
		}
	}
}();

const paginationInput = function () {
	const paginationContainer = document.querySelector('.s31-pagination');
	if (!paginationContainer) {
		return;
	}
	let timeout = null;

	paginationContainer.addEventListener('change', function (event) {
		if (!event.target.classList.contains('s31-pagination-item')) {
			return;
		}

		if (timeout) {
			clearTimeout(timeout);
		}

		let maxPages = parseInt(event.target.getAttribute('data-nbpages'), 10);
		let page = parseInt(event.target.value, 10);

		if (isNaN(maxPages) || isNaN(page)) {
			page = 1;
		}

		if (page > maxPages) {
			page = maxPages;
		}

		timeout = setTimeout(function() {
			Multifilters.applyFilters(page, Multifilters.getActiveSorting(), false, true);
		}, 1000);
	});
};

// document.addEventListener('s31shop.multifilters_applied', function (e) {
// 	automaticPagination.init();
// 	document.dispatchEvent(new CustomEvent('app.automatic_pagination.unobserve',{}));
// });

$(function() {
	// automaticPagination.init();
	paginationInput();

	$(document).on("click", ".s31-listing-bottom-des-button", function() {
		$('html, body').animate({
			scrollTop: $(".s31-listing-bottom-des-long").offset().top
		}, 2000);
	});
});