var checkout_sylius_checkout_form = document.getElementById('s31-form-checkout-address');
var change_password_form = document.getElementById('s31-form-change-password');

// checkout form
if(typeof(checkout_sylius_checkout_form) != 'undefined' && checkout_sylius_checkout_form != null){
    checkout_sylius_checkout_form.addEventListener("submit", validateForm, false);

    var checkout_address_customer_email = document.getElementById('sylius_checkout_address_customer_email');
    checkout_address_customer_email.addEventListener("blur", validateEmail);

    // billingAddress
    var billingAddress_firstName_InputEl = document.getElementById("sylius_checkout_address_billingAddress_firstName");
    billingAddress_firstName_InputEl.addEventListener("blur", validateBillingFirstName);

    var billingAddress_lastName_InputEl = document.getElementById("sylius_checkout_address_billingAddress_lastName");
    billingAddress_lastName_InputEl.addEventListener("blur", validateBillingLastName);

    var billingAddress_postcode_InputEl = document.getElementById("sylius_checkout_address_billingAddress_postcode");
    billingAddress_postcode_InputEl.addEventListener("blur", validateBillingPostCode);

    var billingAddress_city_InputEl = document.getElementById("sylius_checkout_address_billingAddress_city");
    billingAddress_city_InputEl.addEventListener("blur", validateBillingCity);

    var billingAddress_street_InputEl = document.getElementById("sylius_checkout_address_billingAddress_street");
    billingAddress_street_InputEl.addEventListener("blur", validateBillingStreet);

    var billingAddress_streetNumber_InputEl = document.getElementById("sylius_checkout_address_billingAddress_streetNumber");
    billingAddress_streetNumber_InputEl.addEventListener("blur", validateBillingStreetNumber);

    var billingAddress_phoneNumber_InputEl = document.getElementById("sylius_checkout_address_billingAddress_phoneNumber");
    billingAddress_phoneNumber_InputEl.addEventListener("blur", validateBillingPhoneNumber);

    // birthday
    var customer_birthdayYear_InputEl = document.getElementById("sylius_checkout_address_customer_birthday_year");
    customer_birthdayYear_InputEl.addEventListener("blur", validateBirthdayDate);
    var customer_birthdayMonth_InputEl = document.getElementById("sylius_checkout_address_customer_birthday_month");
    customer_birthdayMonth_InputEl.addEventListener("blur", validateBirthdayDate);
    var customer_birthdayDay_InputEl = document.getElementById("sylius_checkout_address_customer_birthday_day");
    customer_birthdayDay_InputEl.addEventListener("blur", validateBirthdayDate);

    // shippingAddress
    var shippingAddress_differentShippingAddress = document.getElementById("sylius_checkout_address_differentShippingAddress");

    var shippingAddress_firstName_InputEl = document.getElementById("sylius_checkout_address_shippingAddress_firstName");
    shippingAddress_firstName_InputEl.addEventListener("blur", validateShippingFirstName);

    var shippingAddress_lastName_InputEl = document.getElementById("sylius_checkout_address_shippingAddress_lastName");
    shippingAddress_lastName_InputEl.addEventListener("blur", validateShippingLastName);

    var shippingAddress_postcode_InputEl = document.getElementById("sylius_checkout_address_shippingAddress_postcode");
    shippingAddress_postcode_InputEl.addEventListener("blur", validateShippingPostCode);

    var shippingAddress_city_InputEl = document.getElementById("sylius_checkout_address_shippingAddress_city");
    shippingAddress_city_InputEl.addEventListener("blur", validateShippingCity);

    var shippingAddress_street_InputEl = document.getElementById("sylius_checkout_address_shippingAddress_street");
    shippingAddress_street_InputEl.addEventListener("blur", validateShippingStreet);

    var shippingAddress_streetNumber_InputEl = document.getElementById("sylius_checkout_address_shippingAddress_streetNumber");
    shippingAddress_streetNumber_InputEl.addEventListener("blur", validateShippingStreetNumber);

    var shippingAddress_phoneNumber_InputEl = document.getElementById("sylius_checkout_address_shippingAddress_phoneNumber");
    shippingAddress_phoneNumber_InputEl.addEventListener("blur", validateShippingPhoneNumber);

    // firm
    var addressTypeCompany = document.getElementById("sylius_checkout_address_addressTypeCompany");

    var billingAddress_company_InputEl = document.getElementById("sylius_checkout_address_billingAddress_company");
    billingAddress_company_InputEl.addEventListener("blur", validateBillingCompany);

    var billingAddress_crn_InputEl = document.getElementById("sylius_checkout_address_billingAddress_crn");
    billingAddress_crn_InputEl.addEventListener("blur", validateBillingCrn);

    var billingAddress_vatId_InputEl = document.getElementById("sylius_checkout_address_billingAddress_vatId");
    billingAddress_vatId_InputEl.addEventListener("blur", validateBillingVatId);

    // heslo
    var optionalRegistration = document.getElementById("sylius_checkout_address_optionalRegistration");

    var user_plainPassword_first_InputEl = document.getElementById("sylius_checkout_address_user_plainPassword_first");
    if(typeof(user_plainPassword_first_InputEl) != 'undefined' && user_plainPassword_first_InputEl != null){
        user_plainPassword_first_InputEl.addEventListener("blur", validateUserPassword);
    }

    var user_plainPassword_second_InputEl = document.getElementById("sylius_checkout_address_user_plainPassword_second");
    if(typeof(user_plainPassword_second_InputEl) != 'undefined' && user_plainPassword_second_InputEl != null){
        user_plainPassword_second_InputEl.addEventListener("blur", validateUserPassword);
    }

    function addError(input){
        input.parentNode.classList.add("error");
        input.parentNode.classList.remove("success");
    }

    function removeError(input){
        let existingErrorMessageElement = input.parentElement.querySelector('.sylius-validation-error');
        if (existingErrorMessageElement) {
            existingErrorMessageElement.remove();
        }

        input.parentNode.classList.remove("error");
        input.parentNode.classList.add("success");
    }

    function removeErrorSucces(input){
        input.parentNode.classList.remove("error");
        input.parentNode.classList.remove("success");
    }

    function addErrorMessage(input, classCss, message){
        let existingErrorMessageElement = input.parentElement.querySelector('.sylius-validation-error');
        if (existingErrorMessageElement) {
            existingErrorMessageElement.classList.add(classCss);
            existingErrorMessageElement.innerHTML = message;
        } else {
            input.insertAdjacentHTML("afterend", '<div class="alert alert-danger pointing label sylius-validation-error '+classCss+'">'+message+'</div>');
        }
    }

    function removeErrorMessage(classCss){
        var data = document.getElementsByClassName(classCss);
        if(typeof(data) != 'undefined' && data != null){
            while(data[0]) {
                data[0].parentNode.removeChild(data[0]);
            }
        }
    }

    function validateEmail(){
        var input_name = checkout_address_customer_email;
        var value = input_name.value;

        const reg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        removeErrorMessage('s31-checkout-error-email');

        if(reg.test(String(value).toLowerCase())){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-email');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-email', formValidationEmail);
            return 1;
        }
    }

    function validateBillingFirstName(){
        var input_name = billingAddress_firstName_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-first-name');

        if(value != ""){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-first-name');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-first-name', formValidationFirstName);
            return 1;
        }
    }

    function validateBillingLastName(){
        var input_name = billingAddress_lastName_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-last-name');

        if(value != ""){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-last-name');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-last-name', formValidationLastName);
            return 1;
        }
    }

    function validateBillingPostCode(){
        var input_name = billingAddress_postcode_InputEl;
        var value = input_name.value;
        let reg = /^[0-9]{4,5}$/;

        const regs = {
            'CZ': /^[1-7]{1}\d{2} ?\d{2}$/,
            'SK': /^(0|[8-9]){1}\d{2} ?\d{2}$/,
            'HU': /^\d{4}$/,
            'PL': /^\d{2}[- ]{0,1}\d{3}$/,
            'RO': /^\d{6}$/
        };

        if (typeof shippingCountryCode !== "undefined" && typeof regs[shippingCountryCode] !== "undefined") {
            reg = regs[shippingCountryCode];
        }

        removeErrorMessage('s31-checkout-error-billing-postcode');

        if(value != "" && reg.test(String(value).toLowerCase())){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-postcode');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-postcode', formValidationZipCode);
            return 1;
        }
    }

    function validateBillingCity(){
        var input_name = billingAddress_city_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-city');

        if(value != ""){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-city');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-city', formValidationCity);
            return 1;
        }
    }

    function validateBillingStreet(){
        var input_name = billingAddress_street_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-street');

        if(value != ""){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-street');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-city', formValidationStreet);
            return 1;
        }
    }

    function validateBillingStreetNumber(){
        var input_name = billingAddress_streetNumber_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-street-number');

        if(value != ""){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-street-number');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-street-number', formValidationStreetNumber);
            return 1;
        }
    }

    function validateBillingPhoneNumber(){
        var input_name = billingAddress_phoneNumber_InputEl;
        var value = input_name.value;
        value = String(value).toLowerCase();
        value = value.replaceAll(' ', '');
        value = value.replaceAll(/[()-]/g, '');
        let reg = /^(0?0420)?(\+?420)?[1-9][0-9]{2}[0-9]{6}$/;

        const regs = {
            'CZ': /^((0?0420)?(\+?420)?[1-9][0-9]{2}[0-9]{6})$|^(((0421)|(00421)|(\+?421))[0-9]{7,9})$/,
            'SK': /^(((00?420)|(\+?420))[1-9][0-9]{2}[0-9]{6})$|^(((0421)|(00421)|(\+?421))?[0-9]{7,9})$/,
            'HU': /^([0-9]{8,9})$|^(([+]36)[0-9]{8,9})$|^((36)[0-9]{8,9})$/,
            'PL': /^(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-8]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}$/,
            'RO': /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/,
            'UA': /^(\+380)[0-9]{7,9}$/
        };

        if (typeof shippingCountryCode !== "undefined" && typeof regs[shippingCountryCode] !== "undefined") {
            reg = regs[shippingCountryCode];
        }

        removeErrorMessage('s31-checkout-error-billing-phone');

        if(reg.test(value)){
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-phone');
            return 0;
        }
        else{
            let message = formValidationPhone;
            if (value !== "") {
                message = formValidationPhoneFormat;
            }
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-phone', message);
            return 1;
        }
    }

    function validateBirthdayYear(){
        var input_name = customer_birthdayYear_InputEl;
        var value = input_name.value;

        var reg = /^[0-9]{4}$/;
        var actualYear = new Date().getFullYear();

        if(value != "" && (!reg.test(value) || value > actualYear)){
            addError(input_name);
            return 1;
        }
        else{
            removeError(input_name);
            return 0;
        }
    }

    function validateBirthdayMonth(){
        var input_name = customer_birthdayMonth_InputEl;
        var value = input_name.value;
        var reg = /^([1-9]|1[0-2])$/;

        if(value != "" && !reg.test(value)){
            addError(input_name);
            return 1;
        }
        else{
            removeError(input_name);
            return 0;
        }
    }

    function validateBirthdayDay(){
        var input_name = customer_birthdayDay_InputEl;
        var value = input_name.value;
        var reg = /^([1-9]|[12][0-9]|3[01])$/;

        if(value != "" && !reg.test(value)){
            addError(input_name);
            return 1;
        }
        else{
            removeError(input_name);
            return 0;
        }
    }

    function validateBirthdayDate(){
        var minAge = 15;//let
        var today = new Date();

        var input_name_day = customer_birthdayDay_InputEl;
        var value_day = input_name_day.value;
        var input_name_month = customer_birthdayMonth_InputEl;
        var value_month = input_name_month.value;
        var input_name_year = customer_birthdayYear_InputEl;
        var value_year = input_name_year.value;

        var regYear = /^[0-9]{4}$/;
        var actualYear = new Date().getFullYear();
        var regMonth = /^(0?[1-9]|1[0-2])$/;
        var regDay = /^(0?[1-9]|[12][0-9]|3[01])$/;

        if(value_day == "" && value_month == "" && value_year == ""){
            removeErrorSucces(input_name_day);
            removeErrorSucces(input_name_month);
            removeErrorSucces(input_name_year);
            return 0;
        }
        else{
            var countError = 0;

            if(!regDay.test(value_day)){
                countError++;
            }

            if(!regMonth.test(value_month)){
                countError++;
            }

            if(!regYear.test(value_year) || value_year > actualYear){
                countError++;
            }

            if(countError != 0){
                addError(input_name_day);
                addError(input_name_month);
                addError(input_name_year);
                return 0;
            }
            else{
                var dateBirth = new Date(value_year + "-" + value_month + "-" + value_day);
                var age = Math.floor((today-dateBirth) / (365.25 * 24 * 60 * 60 * 1000));

                if(age < minAge){
                    addError(input_name_day);
                    addError(input_name_month);
                    addError(input_name_year);
                    return 0;
                }
                else{
                    removeError(input_name_day);
                    removeError(input_name_month);
                    removeError(input_name_year);
                    return 0;
                }
            }
        }
    }

    function isShippingAddress(){
        if (shippingAddress_differentShippingAddress.checked) {
            return true;
        } else {
            return false;
        }
    }

    function validateShippingFirstName(){
        var input_name = shippingAddress_firstName_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-shipping-first-name');

        if(isShippingAddress() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-shipping-first-name', formValidationFirstName);
            return 1;
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-shipping-first-name');
            return 0;
        }
    }

    function validateShippingLastName(){
        var input_name = shippingAddress_lastName_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-shipping-last-name');

        if(isShippingAddress() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-shipping-last-name', formValidationLastName);
            return 1;
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-shipping-last-name');
            return 0;
        }
    }

    function validateShippingPostCode(){
        var input_name = shippingAddress_postcode_InputEl;
        var value = input_name.value;
        let reg = /^[0-9]{5}$/;

        const regs = {
            'CZ': /^[1-7]{1}\d{2} ?\d{2}$/,
            'SK': /^(0|[8-9]){1}\d{2} ?\d{2}$/,
            'HU': /^\d{4}$/,
            'PL': /^\d{2}[- ]{0,1}\d{3}$/,
            'RO': /^\d{6}$/
        };

        if (typeof shippingCountryCode !== "undefined" && typeof regs[shippingCountryCode] !== "undefined") {
            reg = regs[shippingCountryCode];
        }

        removeErrorMessage('s31-checkout-error-shipping-postcode');

        if(isShippingAddress() && !reg.test(String(value).toLowerCase())){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-shipping-postcode', formValidationZipCode);
            return 1;
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-shipping-postcode');
            return 0;
        }
    }

    function validateShippingCity(){
        var input_name = shippingAddress_city_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-shipping-city');

        if(isShippingAddress() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-shipping-city', formValidationCity);
            return 1;
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-shipping-city');
            return 0;
        }
    }

    function validateShippingStreet(){
        var input_name = shippingAddress_street_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-shipping-street');

        if(isShippingAddress() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-shipping-street', formValidationStreet);
            return 1;
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-shipping-street');
            return 0;
        }
    }

    function validateShippingStreetNumber(){
        var input_name = shippingAddress_streetNumber_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-shipping-street-number');

        if(isShippingAddress() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-shipping-street-number', formValidationStreetNumber);
            return 1;
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-shipping-street-number');
            return 0;
        }
    }

    function validateShippingPhoneNumber(){
        var input_name = shippingAddress_phoneNumber_InputEl;
        var value = input_name.value;
        value = String(value).toLowerCase();
        value = value.replaceAll(' ', '');
        value = value.replaceAll(/[()-]/g, '');
        let reg = /^(0?0420)?(\+?420)?[1-9][0-9]{2}[0-9]{6}$/;

        const regs = {
            'CZ': /^((0?0420)?(\+?420)?[1-9][0-9]{2}[0-9]{6})$|^(((0421)|(00421)|(\+?421))[0-9]{7,9})$/,
            'SK': /^(((00?420)|(\+?420))[1-9][0-9]{2}[0-9]{6})$|^(((0421)|(00421)|(\+?421))?[0-9]{7,9})$/,
            'HU': /^([0-9]{8,9})$|^(([+]36)[0-9]{8,9})$|^((36)[0-9]{8,9})$/,
            'PL': /^(?:(?:(?:\+|00)?48)|(?:\(\+?48\)))?(?:1[2-8]|2[2-69]|3[2-49]|4[1-8]|5[0-9]|6[0-35-9]|[7-8][1-9]|9[145])\d{7}$/,
            'RO': /^(\+4|)?(07[0-8]{1}[0-9]{1}|02[0-9]{2}|03[0-9]{2}){1}?(\s|\.|\-)?([0-9]{3}(\s|\.|\-|)){2}$/,
            'UA': /^(\+380)[0-9]{7,9}$/
        };

        if (typeof shippingCountryCode !== "undefined" && typeof regs[shippingCountryCode] !== "undefined") {
            reg = regs[shippingCountryCode];
        }

        removeErrorMessage('s31-checkout-error-shipping-phone');

        if(isShippingAddress()){
            if(reg.test(value)){
                removeError(input_name);
                removeErrorMessage('s31-checkout-error-shipping-phone');
                return 0;
            }
            else{
                let message = formValidationPhone;
                if (value !== "") {
                    message = formValidationPhoneFormat;
                }
                addError(input_name);
                addErrorMessage(input_name, 's31-checkout-error-shipping-phone', message);
                return 1;
            }
            /*
            if(value != "" && !reg.test(String(value).toLowerCase())){
                addError(input_name);
                addErrorMessage(input_name, 's31-checkout-error-shipping-phone', 'Zadejte telefonní číslo bez mezer ve tvaru 123456789.');
                return 1;
            }
            else{
                removeError(input_name);
                removeErrorMessage('s31-checkout-error-shipping-phone');
                return 0;
            }*/
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-shipping-phone');
            return 0;
        }
    }

    function isCompany(){
        if (addressTypeCompany.checked) {
            return true;
        } else {
            return false;
        }
    }

    function validateBillingCompany(){
        var input_name = billingAddress_company_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-company');

        if(isCompany() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-company', formValidationCompany);
            return 1;
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-company');
            return 0;
        }
    }

    function validateBillingCrn(){
        var input_name = billingAddress_crn_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-crn');

        if(isCompany() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-crn', formValidationIco);
            return 1;
        }
        else if(isCompany() && value != ""){
            if(!validateIC(value)){
                addError(input_name);
                addErrorMessage(input_name, 's31-checkout-error-billing-crn', formValidationIco);
                return 1;
            }
            else{
                removeError(input_name);
                removeErrorMessage('s31-checkout-error-billing-crn');
                return 0;
            }
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-crn');
            return 0;
        }
    }

    function validateBillingVatId(){
        var input_name = billingAddress_vatId_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-checkout-error-billing-vatid');

        if(isCompany() && value == ""){
            addError(input_name);
            addErrorMessage(input_name, 's31-checkout-error-billing-vatid', formValidationDic);
            return 1;
        }
        else if(isCompany() && value != ""){
            if(!validateDIC(value)){
                addError(input_name);
                addErrorMessage(input_name, 's31-checkout-error-billing-vatid', formValidationDic);
                return 1;
            }
            else{
                removeError(input_name);
                removeErrorMessage('s31-checkout-error-billing-vatid');
                return 0;
            }
        }
        else{
            removeError(input_name);
            removeErrorMessage('s31-checkout-error-billing-vatid');
            return 0;
        }
    }

    function validateIC(value) {
        try {
            var a = 0;
            if (value.length == 0) {
                return true;
            }

            if(value.length != 8 || !/^\d+$/.test(value)) {
                throw 1;
            }

            var b = value.split('');
            var c = 0;

            for (var i = 0; i < 7; i++) {
                a += (parseInt(b[i]) * (8 - i));
            }

            a = a % 11;
            c = 11 - a;

            if (a == 1) {
                c = 0;
            }

            if (a == 0) {
                c = 1;
            }

            if (a == 10) {
                c = 1;
            }

            if (parseInt(b[ 7]) != c) {
                throw(1);
            }
        } catch(e) {
            return false;
        }

        return true;
    }

    function validateDIC(value) {
        try {
            if (value.length == 0) {
                return false;
            }
            var id = value.substr(0, 2).toUpperCase();
            value = value.substr(2);

            if ((id == "CZ")) {
                if (value.length < 8) {
                    throw 1;
                }

                if (value.length > 11) {
                    throw 1;
                }

                if (value.length == 8) {
                    return validateIC(value);
                } else {
                    if (id == "CZ") {
                        if(validateRC(value, 18)){//rc je platne
                            return true;
                        }
                        else{
                            if(value.length == 9){//dic je CZ+9znaků - kontrola online
                                return true;
                            }
                        }
                    } else {
                        return true;
                    }
                }

                throw 1;
            }

            return false;
        } catch(e) {
            return false;
        }

    }

    function validateRC(value, age) {
        if (!age) age = 0;

        try {
            if (value.length == 0) {
                return true;
            }

            if (value.length < 9 || !/^\d+$/.test(value)) {
                throw 1;
            }

            var year = parseInt(value.substr(0, 2), 10);
            var month = parseInt(value.substr(2, 2), 10);
            var day = parseInt( value.substr(4, 2), 10);
            var ext = parseInt(value.substr(6, 3), 10);

            if ((value.length == 9) && (year < 54)) {
                return true;
            }

            var c = 0;

            if (value.length == 10) {
                c = parseInt(value.substr(9, 1));
            }

            var m = parseInt( value.substr(0, 9)) % 11;

            if (m == 10) {
                m = 0;
            }
            if (m != c) {
                throw 1;
            }

            year += (year < 54) ? 2000 : 1900;

            if ((month > 70) && (year > 2003)) {
                month -= 70;
            } else if (month > 50) {
                month -= 50;
            } else if ((month > 20) && (year > 2003)) {
                month -= 20;
            }

            var d = new Date();

            if ((year + age) > d.getFullYear()) {
                throw 1;
            }

            if (month == 0) {
                throw 1;
            }

            if (month > 12) {
                throw 1;
            }

            if (day == 0) {
                throw 1;
            }

            if (day > 31) {
                throw 1;
            }
        } catch(e) {
            return false;
        }

        return true;
    }

    function isRegistration(){
        if (optionalRegistration.checked) {
            return true;
        } else {
            return false;
        }
    }

    function validateUserPassword(){
        var error = 0;

        if((typeof(user_plainPassword_first_InputEl) != 'undefined' && user_plainPassword_first_InputEl != null) && (typeof(user_plainPassword_second_InputEl) != 'undefined' && user_plainPassword_second_InputEl != null)) {
            var input_name1 = user_plainPassword_first_InputEl;
            var input_name2 = user_plainPassword_second_InputEl;

            var value1 = input_name1.value;
            var value2 = input_name2.value;

            removeErrorMessage('s31-checkout-error-password-first');
            removeErrorMessage('s31-checkout-error-password-second');

            if (isRegistration()) {
                if (value1 == ""){
                    addError(input_name1);
                    addErrorMessage(input_name1, 's31-checkout-error-password-first', formValidationPassword);
                    error = 1;
                }
                else{
                    removeError(input_name1);
                    removeErrorMessage('s31-checkout-error-password-first');
                    error = 0;
                }

                if (value2 == ""){
                    addError(input_name2);
                    addErrorMessage(input_name2, 's31-checkout-error-password-second', formValidationPasswordRepeat);
                    error = 1;
                }
                else{
                    removeError(input_name2);
                    removeErrorMessage('s31-checkout-error-password-second');
                    error = 0;
                }

                if(value1 != "" && value2 != ""){
                    if (value1 != value2) {
                        addError(input_name1);
                        addError(input_name2);
                        addErrorMessage(input_name2, 's31-checkout-error-password-second', formValidationPasswordMatch);
                        error = 1;
                    }
                    else{
                        removeError(input_name1);
                        removeError(input_name2);
                        removeErrorMessage('s31-checkout-error-password-first');
                        removeErrorMessage('s31-checkout-error-password-second');
                        error = 0;
                    }
                }

            } else {
                removeError(input_name1);
                removeError(input_name2);
                error = 0;
            }

        }

        return error;
    }

    function validateForm(e){
        e.preventDefault();

        var count_error = 0;

        count_error += validateEmail();

        count_error += validateBillingFirstName();
        count_error += validateBillingLastName();
        count_error += validateBillingPostCode();
        count_error += validateBillingCity();
        count_error += validateBillingStreet();
        count_error += validateBillingStreetNumber();
        count_error += validateBillingPhoneNumber();

        //count_error += validateBirthdayYear();
        //count_error += validateBirthdayMonth();
        //count_error += validateBirthdayDay();
        count_error += validateBirthdayDate();

        count_error += validateShippingFirstName();
        count_error += validateShippingLastName();
        count_error += validateShippingPostCode();
        count_error += validateShippingCity();
        count_error += validateShippingStreet();
        count_error += validateShippingStreetNumber();
        count_error += validateShippingPhoneNumber();

        count_error += validateBillingCompany();
        count_error += validateBillingCrn();
        count_error += validateBillingVatId();

        count_error += validateUserPassword();
        count_error = 0;
        if(count_error > 0){
            return false;
        }
        else{
            checkout_sylius_checkout_form.submit();
            return true;
        }
    }

}

// change password form
if(typeof(change_password_form) != 'undefined' && change_password_form != null){
    change_password_form.addEventListener("submit", validatePasswordForm, false);

    var change_password_currentPassword_InputEl = document.getElementById('sylius_user_change_password_currentPassword');
    change_password_currentPassword_InputEl.addEventListener("blur", validateCurrentPassword);

    var change_password_newPasswordFirst_InputEl = document.getElementById('sylius_user_change_password_newPassword_first');
    change_password_newPasswordFirst_InputEl.addEventListener("blur", validateNewPassword);

    var change_password_newPasswordSecond_InputEl = document.getElementById('sylius_user_change_password_newPassword_second');
    change_password_newPasswordSecond_InputEl.addEventListener("blur", validateNewPassword);

    function addError(input){
        input.parentNode.classList.add("error");
        input.parentNode.classList.remove("success");
    }

    function removeError(input){
        let existingErrorMessageElement = input.parentElement.querySelector('.sylius-validation-error');
        if (existingErrorMessageElement) {
            existingErrorMessageElement.remove();
        }

        input.parentNode.classList.remove("error");
        input.parentNode.classList.add("success");
    }

    function addErrorMessage(input, classCss, message){
        let existingErrorMessageElement = input.parentElement.querySelector('.sylius-validation-error');
        if (existingErrorMessageElement) {
            existingErrorMessageElement.classList.add(classCss);
            existingErrorMessageElement.innerHTML = message;
        } else {
            input.insertAdjacentHTML("afterend", '<div class="alert alert-danger pointing label sylius-validation-error '+classCss+'">'+message+'</div>');
        }
    }

    function removeErrorMessage(classCss){
        var data = document.getElementsByClassName(classCss);
        if(typeof(data) != 'undefined' && data != null){
            while(data[0]) {
                data[0].parentNode.removeChild(data[0]);
            }
        }
    }

    function validateCurrentPassword(){
        var input_name = change_password_currentPassword_InputEl;
        var value = input_name.value;

        removeErrorMessage('s31-error-change-password-old');

        if(value != ""){
            removeError(input_name);
            removeErrorMessage('s31-error-change-password-old');
            return 0;
        }
        else{
            addError(input_name);
            addErrorMessage(input_name, 's31-error-change-password-old', formValidationPasswordCurrent);
            return 1;
        }
    }

    function validateNewPassword(){
        var input_name1 = change_password_newPasswordFirst_InputEl;
        var input_name2 = change_password_newPasswordSecond_InputEl;
        var value1 = input_name1.value;
        var value2 = input_name2.value;

        var error = 0;

        removeErrorMessage('s31-error-change-password-first');
        removeErrorMessage('s31-error-change-password-second');

        if (value1 == ""){
            addError(input_name1);
            addErrorMessage(input_name1, 's31-error-change-password-first', formValidationPassword);
            error = 1;
        }
        else{
            removeError(input_name1);
            removeErrorMessage('s31-error-change-password-first');
            error = 0;
        }

        if (value2 == ""){
            addError(input_name2);
            addErrorMessage(input_name2, 's31-error-change-password-second', formValidationPasswordRepeat);
            error = 1;
        }
        else{
            removeError(input_name2);
            removeErrorMessage('s31-error-change-password-second');
            error = 0;
        }

        if(value1 != "" && value2 != ""){
            if (value1 != value2) {
                addError(input_name1);
                addError(input_name2);
                addErrorMessage(input_name2, 's31-error-change-password-second', formValidationPasswordMatch);
                error = 1;
            }
            else{
                removeError(input_name1);
                removeError(input_name2);
                removeErrorMessage('s31-error-change-password-first');
                removeErrorMessage('s31-error-change-password-second');
                error = 0;
            }
        }

        return parseInt(error);
    }

    function validatePasswordForm(e){
        e.preventDefault();

        var count_error = 0;

        count_error += validateCurrentPassword();
        count_error += validateNewPassword();

        if(count_error > 0){
            return false;
        }
        else{
            change_password_form.submit();
            return true;
        }
    }
}